<template>
	<div class="grid grid-cols-1 md:grid-cols-2 gap-5 text-white">
		<div class="grid grid-flow-col auto-cols-max gap-2">
			<div>
				<i class="fa-solid fa-location-dot text-base-content"></i>
			</div>
			<div v-html="appLocalAddress"></div>
		</div>
		<div class="grid grid-flow-col auto-cols-max gap-2">
			<div>
				<i class="fa-solid fa-phone text-base-content"></i><br />
				<i class="fa-brands fa-whatsapp text-base-content"></i>
			</div>
			<div>
				<NuxtLink class="link no-underline" :to="appTelePhoneURL">{{ useFormatTelephone(appTelePhone) }}</NuxtLink>
				<br />
				<NuxtLink class="link no-underline" :to="appWhatsAppURL" target="_blank">{{ useFormatTelephone(appWhatsApp) }}</NuxtLink>
			</div>
		</div>
		<div class="grid grid-flow-col auto-cols-max gap-2">
			<div>
				<i class="fa-solid fa-envelope text-base-content"></i>
			</div>
			<div>
				<NuxtLink class="link no-underline" :to="appEmailURL">{{ appEmail }}</NuxtLink>
			</div>
		</div>
		<div class="grid grid-flow-col auto-cols-max gap-2">
			<div>
				<i class="fa-solid fa-location-dot text-base-content"></i>
			</div>
			<div v-html="appOpeningHours"></div>
		</div>
	</div>
</template>
